import React, { useEffect, useContext } from "react";
import Login from "./components/Login/Login";
import "@shoelace-style/shoelace/dist/themes/light.css";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path";
import {Routes, Route, useLocation} from "react-router-dom";
import axios from "axios";
import "./Global.css";
import { registerIconLibrary } from "@shoelace-style/shoelace/dist/utilities/icon-library";
import AuthContext from "./context/AuthContext/AuthContext";
import AppContainer from "./components/AppContainer/AppContainer";
import VerifyEmail from "./components/VerifyEmail/VerifyEmail";
import DonorProfile from "./components/DonorProfile/DonorProfile";
import {
  FailAlertProvider,
  useFailAlert,
} from "./context/FailAlertContext/FailAlertContext";
import { useLayoutEffect } from "react";

setBasePath("https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.9.0/cdn/");

const App = () => {
  const authContext = useContext(AuthContext);

  useLayoutEffect(() => {
    axiosInterceptorRequest();
  }, []);

  useEffect(() => {
    registerBoxiconLibrary();
    registerFontawsomeLibrary();
  }, []);

  const registerFontawsomeLibrary = () => {
    registerIconLibrary("fa", {
      resolver: (name) => {
        const filename = name.replace(/^fa[rbs]-/, "");
        let folder = "regular";
        if (name.substring(0, 4) === "fas-") folder = "solid";
        if (name.substring(0, 4) === "fab-") folder = "brands";
        return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.15.1/svgs/${folder}/${filename}.svg`;
      },
      mutator: (svg) => svg.setAttribute("fill", "currentColor"),
    });
  };

  const registerBoxiconLibrary = () => {
    registerIconLibrary("boxicons", {
      resolver: (name) => {
        let folder = "regular";
        if (name.substring(0, 4) === "bxs-") folder = "solid";
        if (name.substring(0, 4) === "bxl-") folder = "logos";
        return `https://cdn.jsdelivr.net/npm/boxicons@2.0.5/svg/${folder}/${name}.svg`;
      },
      mutator: (svg) => svg.setAttribute("fill", "currentColor"),
    });
  };

  const axiosInterceptorRequest = () => {
    axios.interceptors.request.use(
      (config) => {
        if (authContext?.authContext?.loggedIn) {
          config.headers[
            "Authorization"
          ] = `Token ${authContext.authContext.accessToken.token}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  return (
    <>
      <FailAlertProvider>
        <AxiosFailAlertComponent />
      </FailAlertProvider>
      <Routes>
        <Route path={"/verifyemail"} element={<VerifyEmail />} />
        <Route path={"/donorprofile/:id"} element={<DonorProfile />} />
        {!authContext.authContext.loggedIn ? (
          <>
            <Route path="/*" element={<Login />} />
          </>
        ) : (
          <Route path="/*" element={<AppContainer />} />
        )}
      </Routes>
    </>
  );
};

export default App;

// Seperated component for showing failed axios requests
// to prevent app re-render
const AxiosFailAlertComponent = () => {
  const failAlertContext = useFailAlert();

  useEffect(() => {
    axiosInterceptorResponse();
  }, []);

  const axiosInterceptorResponse = () => {
    axios.interceptors.response.use(
      (config) => {
        return config;
      },
      (error) => {
        let errorMessage = "";

        if (typeof error?.response?.data === "string") {
          errorMessage = error.response.data;
        } else if (
          typeof error.response?.data === "object" &&
          error?.response?.data?.message
        ) {
          errorMessage = error.response.data.message;
        }

        failAlertContext.setMessageAndShow(errorMessage);

        return Promise.reject(error);
      }
    );
  };

  return <></>;
};
