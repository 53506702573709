import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const CustomSelect = ({ value, onChange, options }) => {
  return (
    <Select
      size="small"
      variant="outlined"
      displayEmpty
      fullWidth
      value={value}
      onChange={onChange}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelect;
