import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api_endpoints from "../../api";
import AuthContext from "../../context/AuthContext/AuthContext";
import { dateTimeReadableSimple, numberWithCommas } from "../../utils";
import dayjs from "dayjs";
import { MenuItem, Select, Chip } from "@mui/material";
import PredefinedPeriods from "../../widgets/CustomDatePicker/PredefinedPeriod";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import CustomSelect from "../../widgets/CustomSelect/CustomSelect";
import axios from "axios";

const ManageTransactions = ({ pageTranslation }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [loadingError, setLoadingError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [campaignsData, setCampaignsData] = useState([]);
  const [campaignsMenuOption, setCampaignsMenuOption] = useState([]);
  const [kiosksData, setKiosksData] = useState([]);
  const [rows, setRows] = useState([]);
  const cancelTokenSource = useRef(null);
  const [kiosksMenuOption, setKiosksMenuOption] = useState([]);
  const [emplacementData, setEmplacementData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("today");
  const [tableFilter, setTableFilter] = useState({
    organization_id: authContext.authContext.accessToken.organization_id,
    amount: "",
    location_name: "",
    donor_email: "",
    campaignName: "",
    kioskName: "",
    emplacement: "",
    donor: "",
    campagne: "",
    kiosk: "",
    date: dayjs(new Date()),
    start_date: dayjs(new Date()),
    end_date: dayjs(new Date()),
    start_time: dayjs().startOf("day").format("HH:mm"),
    end_time: dayjs().endOf("day").format("HH:mm"),
  });
  const handlePeriodChange = (event) => {
    const value = event.target.value;
    setSelectedPeriod(value);
  };

  const setpersonalizedDates = (startDate, endDate, starttime, endtime) => {
    setTableFilter({
      ...tableFilter,
      start_date: startDate,
      end_date: endDate,
      start_time: starttime.format("HH:mm"),
      end_time: endtime.format("HH:mm"),
    });
  };

  const columns = [
    {
      field: "amount",
      headerName: pageTranslation.transactions_table_amount || "Montant",
      sortable: true,
      numberSort: true,
    },
    {
      field: "location_name",
      headerName: pageTranslation.transactions_table_location || "Emplacement",
      sortable: true,
      numberSort: false,
    },
    {
      field: "donor_email",
      headerName: pageTranslation.transactions_table_donorEmail || "Donateur",
      sortable: true,
      numberSort: false,
    },
    {
      field: "campaignName",
      headerName: pageTranslation.transactions_table_campaign || "Campagne",
      sortable: true,
      numberSort: false,
    },
    {
      field: "kioskName",
      headerName: pageTranslation.transactions_table_kiosk || "Kiosk",
      sortable: true,
      numberSort: false,
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      numberSort: false,
    },
  ];

  const filters = {
    amount: (
      <CustomTextField
        value={tableFilter.amount || ""}
        onChange={(e) => changeTableFilter("amount", e.target.value)}
        clearFilter={() => clearTableFilter("amount")}
      />
    ),
    location_name: (
      <Select
        size="small"
        variant="outlined"
        className="w-60"
        sx={{ width: "100%" }}
        fullWidth
        displayEmpty
        value={tableFilter.location_name}
        onChange={(e) => {
          changeTableFilter("location_name", e.target.value);
        }}
      >
        <MenuItem
          value={""}
          style={{
            display: emplacementData.length <= 1 ? "none" : "block",
          }}
        >
          Tout
        </MenuItem>
        {emplacementData.map((emplacement, index) => (
          <MenuItem
            key={emplacement._id}
            value={emplacement._id}
            selected={emplacementData.length === 1 && index === 0}
          >
            <div className="flex items-center justify-between">
              <span className="mr-2.5">{emplacement.location_name}</span>
            </div>
          </MenuItem>
        ))}
      </Select>
    ),
    donor_email: (
      <CustomTextField
        value={tableFilter.donor_email || ""}
        onChange={(e) => changeTableFilter("donor_email", e.target.value)}
        clearFilter={() => clearTableFilter("donor_email")}
      />
    ),
    campaignName: (
      <CustomSelect
        value={tableFilter.campaignName}
        onChange={(e) => {
          changeTableFilter("campaignName", e.target.value);
        }}
        options={campaignsMenuOption}
      />
    ),
    kioskName: (
      <CustomSelect
        value={tableFilter.kioskName}
        onChange={(e) => {
          changeTableFilter("kioskName", e.target.value);
        }}
        options={kiosksMenuOption}
      />
    ),
    date: (
      <PredefinedPeriods
        selectedPeriod={selectedPeriod}
        handlePeriodChange={handlePeriodChange}
        setSelectedPeriod={setSelectedPeriod}
        setpersonalizedDates={setpersonalizedDates}
      />
    ),
  };

  useEffect(() => {
    fetchFilterData();
    fetchTransactions();
  }, []);

  useEffect(() => {
    getData();
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  }, [tableFilter, authContext.authContext.accessToken.organization_id]);

  useEffect(() => {
    fetchTransactions();
  }, [
    tableFilter.emplacement,
    tableFilter.campagne,
    tableFilter.kiosk,
    tableFilter.start_date,
    tableFilter.end_date,
  ]);

  useEffect(() => {
    // Populate campaignsMenuOption
    const options = campaignsData.map((campaign) => ({
      value: campaign._id,
      label: (
        <div className="flex flex-1 items-center justify-between">
          <span>{campaign.name}</span>
          <div>
            <Chip
              color={campaign.enabled ? "success" : "error"}
              label={campaign.enabled ? "Active" : "Désactivé"}
              size="small"
            />
          </div>
        </div>
      ),
    }));

    // Add "Tout" option at the beginning
    options.unshift({ value: "", label: "Tout" });

    // Update state
    setCampaignsMenuOption(options);
  }, [campaignsData]);

  useEffect(() => {
    // Populate kiosksMenuOption
    const options = kiosksData.map((kiosk) => ({
      value: kiosk._id,
      label: (
        <div className="flex flex-1 items-center justify-between">
          <span>{kiosk.name}</span>
          <div>
            <Chip
              color={kiosk.is_active ? "success" : "error"}
              label={kiosk.is_active ? "Active" : "Désactivé"}
              size="small"
            />
          </div>
        </div>
      ),
    }));

    // Add "Tout" option at the beginning
    options.unshift({ value: "", label: "Tout" });

    // Update state
    setKiosksMenuOption(options);
  }, [kiosksData]);

  const getData = async () => {
    setLoading(true); // Set loading state to true before making the request
    setLoadingError(false); // Reset error state to false before making the request
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSource.current = axios.CancelToken.source();
    try {
      const data = await fetchTransactions(cancelTokenSource.current.token);
      setRows(data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error(error);
        setLoadingError(true);
      }
    } finally {
      setLoading(false); // Set loading state to false after request completes or fails
    }
  };

  const formatDate = (dateString, timeString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day} ${timeString}`;
  };

  const changeTableFilter = (option, value) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: value }));
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
    if (emplacementData.length === 1) {
      changeTableFilter("location_name", emplacementData[0]._id);
    }
  };

  const fetchFilterData = async () => {
    try {
      //setLoading(true);
      const [responseCampaigns, responseKiosks, responseLocations] =
        await Promise.all([
          api_endpoints.getCampaigns(
            authContext.authContext.accessToken.organization_id
          ),
          api_endpoints.getKiosks(
            authContext.authContext.accessToken.organization_id
          ),
          api_endpoints.getLocations(
            authContext.authContext.accessToken.organization_id
          ),
        ]);
      // Check if all responses are successful (status code 200)
      if (
        responseCampaigns.status === 200 &&
        responseKiosks.status === 200 &&
        responseLocations.status === 200
      ) {
        // Process responses
        if (responseCampaigns.data.length > 0) {
          setCampaignsData(responseCampaigns.data);
        }
        if (responseKiosks.data.length > 0) {
          setKiosksData(responseKiosks.data);
        }
        if (responseLocations.data.length > 0) {
          setEmplacementData(responseLocations.data);
          if (responseLocations.data.length === 1) {
            changeTableFilter("emplacement", responseLocations.data[0]._id);
          }
        }
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching filter data:", error);
    }
    // setLoading(false);
  };

  const fetchTransactions = async () => {
    setLoading(true);
    const startDate = formatDate(
      tableFilter.start_date,
      tableFilter.start_time
    );
    const endDate = formatDate(tableFilter.end_date, tableFilter.end_time);
    try {
      let response = await api_endpoints.getTransactions(
        tableFilter.organization_id,
        tableFilter.amount,
        tableFilter.location_name,
        tableFilter.donor_email,
        tableFilter.campaignName,
        tableFilter.kioskName,
        startDate,
        endDate
      );
      if (response.status === 200) {
        setLoading(false);
        return response.data.map((transaction) => ({
          id: transaction._id,
          amount: `${numberWithCommas(transaction.amount)}$`,
          campaignName: transaction.campaign?.name,
          location_name: transaction.campaign_location?.location_name,
          location_add: transaction.campaign_location?.address,
          donor_email: transaction.donor?.email,
          kioskName: transaction.cardTransaction.kiosk[0]?.name,
          date: dateTimeReadableSimple(transaction.created_at),
        }));
      }
    } catch (e) {
      setLoadingError(true);
    }
    setLoading(false);
  };

  const mapTableData = (transactions) => {
    let data = [];

    for (let transaction of transactions) {
      data.push({
        id: transaction._id,
        amount: `${numberWithCommas(transaction.amount)}$`,
        campaignName: transaction.campaign?.name,
        location_name: transaction.campaign_location?.location_name,
        location_add: transaction.campaign_location?.address,
        donor_email: transaction.donor?.email,
        kioskName: transaction.cardTransaction.kiosk[0]?.name,
        date: dateTimeReadableSimple(transaction.created_at),
      });
    }
    setTableData(data);
  };

  return (
    <>
      <div className="flex flex-1 h-full mt-5 flex-col">
        <DataGrid
          columns={columns}
          rows={rows}
          filters={filters}
          loading={loading}
        />
      </div>
    </>
  );
};

export default ManageTransactions;
