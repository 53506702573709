import PageTitle from "../../widgets/PageTitle/PageTitle";
import styles from "./CreateLocation.module.css";
import {getPageFromName, PAGES} from "../../route_utils";
import { useEffect, useState, useContext } from "react";
import AuthContext from "../../context/AuthContext/AuthContext";
import api_endpoints from "../../api";
import { useNavigate } from "react-router-dom";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import {
  MenuItem,
  Select,
  TextField,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import ImageInput from "../../widgets/ImageInput/ImageInput";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";

const CreateLocation = ({ pageTranslation }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [users, setUsers] = useState([]);
  const [image, setImage] = useState(null);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();

  useEffect(() => {
    fetchUsers();
  }, []);

  const [formData, setFormData] = useState({
    organization: authContext.authContext.accessToken.organization_id,
    location_name: "",
    address: "",
    phone: "",
    person_responsible: "",
  });

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await api_endpoints.getUsers(
        authContext.authContext.accessToken.organization_id
      );

      if (response.status === 200) {
        if (!formData.person_responsible) {
          changeFormData("person_responsible", response.data.users[0]._id);
        }
        setUsers(response.data.users);
      } else {
        setLoadingError(true);
      }
    } catch (e) {
      setLoadingError(true);
      console.error(e);
    }
    setLoading(false);
  };

  const handleCreateCampaign = async (e) => {
    setSubmitting(true);
    e?.preventDefault();

    const data = setRequestData();

    try {
      let response = await api_endpoints.createLocation(data);

      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);
        navigate(getPageFromName("Emplacements").path);
      }
    } catch (e) {}

    setSubmitting(false);
  };

  const setRequestData = () => {
    const data = new FormData();

    if (image) data.append("file", image, image.name);

    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value);
    }

    return data;
  };

  const changeFormData = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSelectImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const removeImage = () => {
    document.getElementById("imageInput").value = null;
    setImage(null);
  };

  return (
    <AccessControl allowedPermissions={["is_super_user"]}>
      <LoadingContainer loading={loading} error={loadingError}>
        <form
          id="publish_campaign_form"
          onSubmit={handleCreateCampaign}
          autoComplete="off"
        >
          <div className={`${styles.details_container} mt-4`}>
            <div className="text-lg">
              {pageTranslation.create_location_header ||
                "Détails de l'emplacement"}
            </div>
            <div className="[&>div]:flex [&>div]:items-center [&>div]:mt-4 [&>div]:space-x-4">
              <div>
                <TextField
                  fullWidth
                  placeholder={
                    pageTranslation.create_location_location_name ||
                    "Nom de l'emplacement"
                  }
                  label={
                    pageTranslation.create_location_location_name ||
                    "Nom de l'emplacement"
                  }
                  required
                  value={formData.location_name}
                  onInput={(e) =>
                    changeFormData("location_name", e.target.value)
                  }
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  placeholder="Adresse"
                  label="Adresse"
                  required
                  value={formData.address}
                  onInput={(e) => changeFormData("address", e.target.value)}
                />
                <TextField
                  fullWidth
                  placeholder={
                    pageTranslation.create_location_location_phone ||
                    "Numéro de téléphone"
                  }
                  label={
                    pageTranslation.create_location_location_phone ||
                    "Numéro de téléphone"
                  }
                  required
                  value={formData.phone}
                  onInput={(e) => changeFormData("phone", e.target.value)}
                />
              </div>
              <div>
                <FormControl fullWidth required>
                  <InputLabel>
                    {pageTranslation.create_location_manager ||
                      "Personne responsable"}
                  </InputLabel>
                  <Select
                    fullWidth
                    value={formData.person_responsible}
                    required
                    label={
                      pageTranslation.create_location_manager ||
                      "Personne responsable"
                    }
                    onChange={(e) =>
                      changeFormData("person_responsible", e.target.value)
                    }
                  >
                    {users.map((user, index) => (
                      <MenuItem value={user._id} key={user._id}>
                        <div className="flex flex-col">
                          <div className="text-lg font-semibold">{`${user.last_name} ${user.first_name} `}</div>
                          <div>{user.email}</div>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <AccessControl allowedPermissions={["is_super_user"]}>
                <div>
                  <ImageInput
                    image={image}
                    onChange={handleSelectImage}
                    removeImage={removeImage}
                    pageTranslation={pageTranslation}
                    placeholder={
                      pageTranslation.create_location_splashscreen ||
                      "Ajouter un splash screen"
                    }
                    helperText={
                      pageTranslation.create_location_imageformattext ||
                      "Les formats suivants sont acceptés : .png .gif .jpeg"
                    }
                  />
                </div>
              </AccessControl>
            </div>
          </div>
          <div className="flex mt-4 justify-end space-x-4">
            <CancelButton
              disabled={submitting}
              label={pageTranslation.create_location_cancel_button || "Annuler"}
            />
            <ButtonLoading
              loading={submitting}
              type="submit"
              variant="contained"
            >
              {pageTranslation.create_location_create_button || "Créer"}
            </ButtonLoading>
          </div>
        </form>
      </LoadingContainer>
    </AccessControl>
  );
};

export default CreateLocation;
