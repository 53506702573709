import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api_endpoints from "../../api";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import LoadingContainer from "../LoadingContainer/LoadingContainer";

const Localization = ({ pageTranslation }) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCountryId, setModalCountryId] = useState(null); // Track the currently open country modal
  const [modalCountry, setModalCountry] = useState(null); // Store the current country object
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const [modalLoading, setModalLoading] = useState(false);
  const [modalLoadingError, setModalLoadingError] = useState(false);
  const [locales, setLocales] = useState([]);
  const [selectedLocales, setSelectedLocales] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [rows, setRows] = useState([]);
  const cancelTokenSource = useRef(null);
  const [tableFilter, setTableFilter] = useState({
    country_name: "",
    country_code: "",
  });

  const columns = [
    {
      field: "countryName",
      headerName: "Nom du pays",
      sortable: true,
      numberSort: false,
    },
    {
      field: "countryCode",
      headerName: "Code postal",
      sortable: true,
      numberSort: false,
    },
  ];

  const filters = {
    countryName: (
      <CustomTextField
        value={tableFilter.country_name || ""}
        onChange={(e) => changeTableFilter("country_name", e.target.value)}
        clearFilter={() => clearTableFilter("country_name")}
      />
    ),
    countryCode: (
      <CustomTextField
        value={tableFilter.country_code || ""}
        onChange={(e) => changeTableFilter("country_code", e.target.value)}
        clearFilter={() => clearTableFilter("country_code")}
      />
    ),
  };

  const fetchCountries = async () => {
    setLoading(true);
    let response = await api_endpoints.getCountries(
      tableFilter.country_name,
      tableFilter.country_code
    );

    if (response.status === 200) {
      setLoading(false);
      return response.data.map((country) => ({
        id: country._id,
        countryName: country.name,
        countryCode: country.code,
      }));
    }
  };

  const getData = async () => {
    setLoading(true); // Set loading state to true before making the request
    setLoadingError(false); // Reset error state to false before making the request
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSource.current = axios.CancelToken.source();
    try {
      const data = await fetchCountries(cancelTokenSource.current.token);
      setRows(data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error(error);
        setLoadingError(true);
      }
    } finally {
      setLoading(false); // Set loading state to false after request completes or fails
    }
  };

  useEffect(() => {
    getData();
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  }, [tableFilter]);

  const changeTableFilter = (option, value) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: value }));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalCountryId(null);
    setModalCountry(null); // Reset modalCountry state
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
  };

  const openModal = async (country) => {
    setModalOpen(true);
    setModalCountryId(country.id);
    setModalLoading(true);
    setModalCountry(null); // Reset modalCountry state initially

    try {
      let response = await api_endpoints.getAllLocales();
      if (response.status === 200) {
        const formattedLocales = response.data.locales.map((locale) => ({
          id: locale._id,
          label: `${locale.locale_translated} (${locale.locale_abbreviation})`,
        }));
        setLocales(formattedLocales);
      }
      // Set the current country object
      setModalCountry(country);

      let countryLocalesResponse = await api_endpoints.getCountryLocales(
        country.id
      );
      if (countryLocalesResponse.status === 200) {
        const selected = countryLocalesResponse.data.map((locale) => ({
          id: locale.locale._id,
          label: `${locale.locale.locale} (${locale.locale.locale_abbreviation})`,
        }));
        setSelectedLocales((prevSelected) => ({
          ...prevSelected,
          [country.id]: selected,
        }));
      }
    } catch (error) {
      console.error("Error fetching locales", error);
      setModalLoadingError(true);
    } finally {
      setModalLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const selected = selectedLocales[modalCountryId] || [];
      const localeIds = selected.map((locale) => locale.id);

      let response = await api_endpoints.editCountryLocales(
        modalCountryId,
        localeIds
      );
      if (response.status === 200) {
        setMessageSuccessAlert(response.data.message);
        setShowSuccessAlert(true);
      } else {
        console.error("Failed to update country locales");
      }
    } catch (error) {
      console.error("Error updating country locales", error);
    } finally {
      setModalOpen(false);
      setModalCountryId(null);
      setModalCountry(null);
    }
  };
  const handleChipDelete = (localeToDelete) => {
    setSelectedLocales((prevSelected) => {
      const updatedLocales = { ...prevSelected };
      updatedLocales[modalCountryId] = updatedLocales[modalCountryId].filter(
        (locale) => locale.id !== localeToDelete.id
      );
      return updatedLocales;
    });
  };

  const handleLocaleSelect = (event, value) => {
    setSelectedLocales((prevSelected) => ({
      ...prevSelected,
      [modalCountryId]: value,
    }));
  };

  return (
    <>
      <Dialog
        open={modalOpen} // Use unified modal state
        onClose={handleCloseModal}
        sx={{ "& .MuiDialog-paper": { minHeight: 70, minWidth: 400 } }}
      >
        <DialogTitle>
          {modalLoading ||
            `Sélectionnez les langues ${modalCountry?.countryName || ""}`}
        </DialogTitle>
        <DialogContent>
          <LoadingContainer loading={modalLoading} error={modalLoadingError}>
            <div className="flex flex-1 flex-col space-y-4">
              <Autocomplete
                options={locales} // List of all available locales
                getOptionLabel={(option) => option.label}
                onChange={handleLocaleSelect}
                value={selectedLocales[modalCountryId] || []}
                multiple
                isOptionEqualToValue={(option, value) => option.id === value.id}
                fullWidth
                renderTags={() => null}
                disableClearable
                renderInput={(params) => (
                  <TextField {...params} label="Select Languages" />
                )}
              />
              <div className="flex flex-wrap gap-2">
                {(selectedLocales[modalCountryId] || []).map((locale) => (
                  <Chip
                    key={locale.id}
                    label={locale.label}
                    onDelete={() => handleChipDelete(locale)}
                  />
                ))}
              </div>
            </div>
          </LoadingContainer>
        </DialogContent>
        <DialogActions>
          <ButtonLoading
            loading={modalLoading}
            variant="contained"
            onClick={handleSubmit}
          >
            Save
          </ButtonLoading>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setModalOpen(false)} // Correctly close the modal
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div className="text-2xl flex justify-between">
        <div className="text-gray-500 text-sm font-normal"></div>
      </div>
      <div>
        <div className="flex flex-1 h-full mt-5 flex-col">
          <DataGrid
            columns={columns}
            rows={rows}
            showDetails={(params) => openModal(params.row)}
            filters={filters}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};
export default Localization;
