const MIN_PASS_LENGTH = 8;

export const validPassword = (password) => {
  let validRegex =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*()'";:{}<>,[.?/_+\-|]{8,}$/;

  return password.length >= MIN_PASS_LENGTH && validRegex.test(password)
    ? true
    : false;
};

export const numberWithCommas = (number) => {
  if (!number) return "";

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const validEmail = (email) => {
  let validRegex =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*()'";:{}<>,[.?/_+\-|]{8,}$/;
  return email.match(validRegex) ? true : false;
};

export const validName = (name) => {
  const regName = /^[a-zA-Z ]{2,30}$/;

  return regName.test(name) ? true : false;
};

export const validCurrencyAmount = (amount) => {
  const regex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;

  if (isNaN(amount) || !regex.test(amount)) return false;

  return true;
};

export const dateTimeReadable = (datetime, locale = "fr-CA") => {
  return new Intl.DateTimeFormat(locale, {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(new Date(datetime));
};

export const dateTimeReadableSimple = (datetime, locale = "fr-CA") => {
  let datestring = "";

  const date = new Date(datetime);

  datestring = date.toISOString().replace("T", " ").split(".")[0];

  return datestring;
};
