import React, { useEffect, useState } from "react";

const FormController = (props) => {
  const [value, setValue] = useState({ ...props.initialValues });

  useEffect(() => {
    setValue({ ...props.initialValues });
  }, [props.initialValues]);

  const handleChange = (e) => {
    const stateCopy = { ...value };
    const splitTargetName = e.target.name.split(".");
    let lastKey = splitTargetName.pop();
    let nestedObject = splitTargetName.reduce((xs, x) => xs[x], stateCopy);

    if (e.target.type === "checkbox") {
      nestedObject[lastKey] = e.target.checked;
    } else {
      nestedObject[lastKey] = e.target.value;
    }

    setValue({ ...stateCopy });
  };

  const getFormFields = () => {
    return value;
  };

  return <>{props.children({ value, handleChange, getFormFields })}</>;
};

export default FormController;
