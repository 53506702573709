import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./context/AuthContext/AuthProvider";
import { SuccessAlertProvider } from "./context/SuccessAlertContext/SuccessAlertContext";
import "./index.css";
import { ThemeProvider } from "@mui/material/styles";
import { colors } from "./material-ui-utils/colors";
import { LocaleProvider } from "./context/LocaleContext/LocaleContext";
import {HiddenViewProvider} from "./context/HiddenViewContext/HiddenViewContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <LocaleProvider>
      <BrowserRouter>
        <ThemeProvider theme={colors}>
          <SuccessAlertProvider>
              <HiddenViewProvider>
                  <App />    
              </HiddenViewProvider>
          </SuccessAlertProvider>
        </ThemeProvider>
      </BrowserRouter>
    </LocaleProvider>
  </AuthProvider>
);
