import {
  Card,
  CardContent,
  Avatar,
  useTheme,
  Grid,
  Button,
  TextField,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import PageTitle from "../../widgets/PageTitle/PageTitle";
import { PAGES } from "../../route_utils";
import { useContext, useEffect, useState, forwardRef } from "react";
import api_endpoints from "../../api";
import AuthContext from "../../context/AuthContext/AuthContext";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import NoElements from "../../widgets/NoElements/NoElements";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { Link } from "react-router-dom";
import TableVirtuoso from "../../widgets/TableVirtuoso/TableVirtuoso";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import DonationImportation from "../DonationImportation/DonationImportation";

const Donors = () => {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [searchFilter, setSearchFilter] = useState({ search: "", sort: 1 });
  const [donors, setDonors] = useState([]);
  const theme = useTheme();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [generateReceiptDialogOpen, setGenerateReceiptDialogOpen] =
    useState(false);
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [donorsReceiptStatus, setDonorsReceiptStatus] = useState([]);
  const [receiptsDone, setReceiptsDone] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);

  useEffect(() => {
    fetchDonors();
    createYearOptionsReceipt();
  }, []);

  useEffect(() => {
    fetchDonors();
  }, [searchFilter.sort]);

  useEffect(() => {
    if (searchFilter.search === "" && searchFilter.sort === 1) {
      fetchDonors();
    }
  }, [searchFilter]);

  useEffect(() => {
    if (donors.length > 0) initDonorsReceiptStatus();
  }, [donors]);

  const initDonorsReceiptStatus = () => {
    setDonorsReceiptStatus([
      ...donors.map((donor) => ({
        email: donor.email,
        status: <CircularProgress size="20px" />,
      })),
    ]);
  };

  const fetchDonors = async () => {
    setLoading(true);
    try {
      const response = await api_endpoints.getDonors(
        searchFilter.search,
        searchFilter.sort,
        authContext.authContext.accessToken.organization_id
      );

      if (response.status === 200) {
        setDonors(response.data);
      }
    } catch (e) {
      setLoadingError(true);
    }
    setLoading(false);
  };

  const createYearOptionsReceipt = () => {
    let data = [];
    const minYear = 2024;
    let yearOptions = Number(new Date().getFullYear());
    const iterations = yearOptions - minYear;

    for (let i = 0; i <= iterations; i++) {
      data.push(yearOptions--);
    }

    setYearOptions(data);
  };

  const handleFilterChange = (key, value) => {
    setSearchFilter({ ...searchFilter, [key]: value });
  };

  const clearFilters = () => {
    setSearchFilter({ search: "", sort: 1 });
  };

  const onSortChange = (value) => {
    setSearchFilter({ ...searchFilter, sort: value });
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const openConfirmationDialog = () => {
    setConfirmationDialogOpen(true);
  };

  const openGenerateReceiptDialog = () => {
    setGenerateReceiptDialogOpen(true);
    generateDonorsReceipt();
  };

  const closeGenerateReceiptDialog = () => {
    setGenerateReceiptDialogOpen(false);
    initDonorsReceiptStatus();
  };

  const openImportDialog = () => {
    setImportDialogOpen(true);
  };

  const closeImportDialog = () => {
    setImportDialogOpen(false);
  };

  const handleDonorsReceiptStatusChange = (status, donorEmail) => {
    if (status !== 1 && status !== 2) return;

    const tempDonorsReceiptStatus = [...donorsReceiptStatus];
    const index = tempDonorsReceiptStatus.findIndex((donor) => {
      return donor.email === donorEmail;
    });

    let donorStatus = null;

    if (status === 1) {
      donorStatus = (
        <div style={{ color: theme.palette.success.main, fontWeight: 600 }}>
          Succès
        </div>
      );
    } else if (status === 2) {
      donorStatus = (
        <div style={{ color: theme.palette.error.main, fontWeight: 600 }}>
          Erreur
        </div>
      );
    }

    tempDonorsReceiptStatus[index].status = donorStatus;

    setDonorsReceiptStatus(tempDonorsReceiptStatus);
  };

  const generateDonorsReceipt = async () => {
    for (const donor of donors) {
      try {
        const response = await api_endpoints.generateReceipt(
          donor._id,
          authContext.authContext.accessToken.organization_id,
          selectedYear
        );

        if (response.status === 200) {
          handleDonorsReceiptStatusChange(1, donor.email);
        } else {
          handleDonorsReceiptStatusChange(2, donor.email);
        }
      } catch (e) {
        handleDonorsReceiptStatusChange(2, donor.email);
        console.error(e);
      }
    }
    setReceiptsDone(true);
  };

  return (
    <>
      <Dialog
        open={generateReceiptDialogOpen}
        onClose={closeGenerateReceiptDialog}
        fullScreen
      >
        <DialogTitle>Générer reçus d'impôts</DialogTitle>
        <DialogContent>
          <div className="flex flex-1 flex-col h-full">
            <div>
              Traitement des reçus d'impôts pour l'année
              <span className="font-semibold"> {selectedYear}</span>
            </div>
            <div className="flex flex-1 h-full mt-4">
              <TableVirtuoso
                rows={donorsReceiptStatus}
                columns={[
                  { key: "email", label: "Donateur" },
                  { key: "status", label: "Statut" },
                ]}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={!receiptsDone}
            onClick={closeGenerateReceiptDialog}
          >
            Terminer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmationDialogOpen} onClose={closeConfirmationDialog}>
        <DialogTitle>Générer reçus d'impôts</DialogTitle>
        <DialogContent>
          <div className="flex flex-1 flex-col">
            <div>
              Générer les reçus d'impôts à tous les donateurs pour l'année
            </div>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              size="small"
            >
              {yearOptions.map((year) => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
            <div
              className="mt-6"
              style={{ color: `${theme.palette.error.main}` }}
            >
              Ce processus peut prendre quelques minutes à terminer.
              Assurez-vous de ne pas fermer ou rafraichir la page afin de ne pas
              interrompre le traitement.
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={closeConfirmationDialog}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              closeConfirmationDialog();
              openGenerateReceiptDialog();
            }}
          >
            Générer
          </Button>
        </DialogActions>
      </Dialog>
      <DonationImportation
        open={importDialogOpen}
        onClose={closeImportDialog}
        onOpen={openImportDialog}
      />
      <div className="flex justify-end space-x-4">
        <AccessControl allowedPermissions={["is_owner", "is_super_user"]}>
          <Button variant="contained" onClick={openImportDialog}>
            Importer dons
          </Button>
          <Button variant="contained" onClick={openConfirmationDialog}>
            Générer reçus
          </Button>
        </AccessControl>
      </div>
      <div className="container-main !flex-row [&>div]:flex space-x-4 items-end">
        <div className="flex-1 flex-col">
          Rechercher
          <TextField
            placeholder="Rechercher"
            size="small"
            InputProps={{ startAdornment: <SearchIcon className="mr-2" /> }}
            value={searchFilter.search}
            onInput={(e) => handleFilterChange("search", e.target.value)}
          />
        </div>
        <div className="flex-col">
          Tri par
          <Select
            size="small"
            value={searchFilter.sort}
            onChange={(e) => onSortChange(e.target.value)}
          >
            <MenuItem value={1}>
              <NorthIcon />
              Ordre croissant
            </MenuItem>
            <MenuItem value={-1}>
              <SouthIcon /> Ordre décroissant
            </MenuItem>
          </Select>
        </div>
        <div className="space-x-4">
          <ButtonLoading
            loading={loading}
            variant="outlined"
            onClick={fetchDonors}
          >
            <FilterAltIcon className="mr-2" /> Filtrer
          </ButtonLoading>
          <Button color="secondary" onClick={clearFilters}>
            Effacer Filtres
          </Button>
        </div>
      </div>
      <LoadingContainer loading={loading} error={loadingError}>
        <NoElements
          elements={donors}
          noElementsText={"Aucun profil donateur à afficher"}
          onRefresh={fetchDonors}
        >
          <Grid container spacing={2}>
            {donors.map((donor) => (
              <Grid item key={donor._id} xs={12} sm={12} md={12} lg={6} xl={4}>
                <Card className="flex h-[120px] w-full">
                  <MenuItem className="w-full h-full">
                    <Link
                      to={`/donors/${donor.email}`}
                      className="flex w-full h-full"
                    >
                      <CardContent className="flex flex-row items-center space-x-4">
                        <Avatar
                          sx={{
                            bgcolor: theme.palette.info.main,
                          }}
                        >
                          {donor.email[0].toUpperCase()}
                        </Avatar>
                        <div className="flex flex-1 flex-col [&>label]:text-lg [&>span]:text-gray-500">
                          <label>
                            <EmailOutlinedIcon /> {donor.email}
                          </label>
                          {donor.first_name ? (
                            <span>
                              <PersonOutlinedIcon fontSize={"small"} />
                              {donor.first_name} {donor.last_name}
                            </span>
                          ) : (
                            <></>
                          )}
                          {donor.phone ? (
                            <span>
                              <PhoneOutlinedIcon fontSize={"small"} />{" "}
                              {donor.phone}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </CardContent>
                    </Link>
                  </MenuItem>
                </Card>
              </Grid>
            ))}
          </Grid>
        </NoElements>
      </LoadingContainer>
    </>
  );
};

export default Donors;
