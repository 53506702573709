import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import api_endpoints from "../../api";
import PageTitle from "../../widgets/PageTitle/PageTitle";
import { PAGES } from "../../route_utils";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import {
  Divider,
  Card,
  CardContent,
  Stack,
  Select,
  MenuItem,
  useTheme,
  Container,
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import NoElements from "../../widgets/NoElements/NoElements";
import AuthContext from "../../context/AuthContext/AuthContext";
import { dateTimeReadable, numberWithCommas } from "../../utils";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableVirtuoso from "../../widgets/TableVirtuoso/TableVirtuoso";

const Donor = () => {
  const authContext = useContext(AuthContext);
  const params = useParams();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [loadingTransactions, setLoadingTransaction] = useState(true);
  const [loadingTransactionsError, setLoadingTransactionError] =
    useState(false);
  const [donor, setDonor] = useState({});
  const [donorTransactions, setDonorTransaction] = useState([]);
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [lastReceiptStatus, setLastReceiptStatus] = useState([]);
  const [lastCurrentYearReceiptStatus, setLastCurrentYearReceiptStatus] =
    useState({});
  const [tabValue, setTabValue] = useState(0);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();

  useEffect(() => {
    fetchDonorProfile();
    createYearOptionsReceipt();
  }, []);

  useEffect(() => {
    if (Object.keys(donor).length !== 0) fetchDonorLastReceiptStatus();
  }, [donor]);

  useEffect(() => {
    if (selectedYear) {
      fetchDonorTransactions();

      if (Object.keys(donor).length !== 0) fetchDonorLastReceiptStatus();
    }
  }, [selectedYear]);

  const fetchDonorProfile = async () => {
    setLoading(true);
    try {
      const response = await api_endpoints.getDonor(params.email);

      if (response.status === 200) setDonor(response.data);
    } catch (e) {
      setLoadingError(true);
    }
    setLoading(false);
  };

  const fetchDonorTransactions = async () => {
    setLoadingTransaction(true);

    try {
      const response = await api_endpoints.getDonorTransactions(
        authContext.authContext.accessToken.organization_id,
        params.email,
        selectedYear
      );

      if (response.status === 200) setDonorTransaction(response.data);
    } catch (e) {
      setLoadingTransactionError(true);
    }

    setLoadingTransaction(false);
  };

  const fetchDonorLastReceiptStatus = async () => {
    try {
      const response = await api_endpoints.getDonorLastReceiptStatus(
        donor._id,
        authContext.authContext.accessToken.organization_id,
        selectedYear
      );

      if (response.status === 200) {
        const lastCurrentYearReceiptStatusIndex = response.data.findIndex(
          (receipt) => {
            return selectedYear === receipt.year;
          }
        );
        setLastCurrentYearReceiptStatus(
          response.data[lastCurrentYearReceiptStatusIndex]
        );

        setLastReceiptStatus([
          ...response.data.map((receipt) => ({
            year: receipt.year,
            status: (
              <div
                style={{
                  color: receipt.success
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                  fontWeight: 600,
                }}
              >
                {" "}
                {receipt.success ? "Envoyé" : "Échec d'envoi"}
              </div>
            ),
          })),
        ]);
      }
    } catch (e) {}
  };

  const generateReceipt = async () => {
    setLoadingReceipt(true);
    try {
      const response = await api_endpoints.generateReceipt(
        donor._id,
        authContext.authContext.accessToken.organization_id,
        selectedYear
      );

      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);
        fetchDonorLastReceiptStatus();
      }
    } catch (e) {}
    setLoadingReceipt(false);
  };

  const createYearOptionsReceipt = () => {
    let data = [];
    const minYear = 2024;
    let yearOptions = Number(new Date().getFullYear());
    const iterations = yearOptions - minYear;

    for (let i = 0; i <= iterations; i++) {
      data.push(yearOptions--);
    }

    setYearOptions(data);
  };

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  return (
    <LoadingContainer loading={loading} error={loadingError}>
      <div className="container-main space-y-4">
        <div className="flex flex-1 justify-between">
          <div className="[&>div]:space-x-2 [&>div]:text-xl space-y-1">
            <div className="!text-2xl font-semibold">
              <EmailOutlinedIcon />
              <label>{donor.email}</label>
            </div>

            {donor.first_name ? (
              <div className="text-xl">
                <PersonOutlinedIcon />
                <label>
                  {donor.first_name} {donor.last_name}
                </label>
              </div>
            ) : (
              <></>
            )}

            {donor.phone ? (
              <div>
                <PhoneOutlinedIcon />
                <label>{donor.phone}</label>
              </div>
            ) : (
              <></>
            )}

            {donor.adresse ? (
              <div>
                <HomeOutlinedIcon />
                <label>{donor.adresse}</label>
              </div>
            ) : (
              <></>
            )}

            {donor.company ? (
              <div>
                <WorkOutlineOutlinedIcon />
                <label>{donor.company}</label>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex space-x-4 w-full justify-end">
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                size="small"
              >
                {yearOptions.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
              <ButtonLoading
                loading={loadingReceipt}
                variant="contained"
                onClick={generateReceipt}
              >
                Générer reçu
              </ButtonLoading>
            </div>
            {lastCurrentYearReceiptStatus?.created_at ? (
              <div className="w-[250px] items-end">
                Généré le{" "}
                <span className="font-semibold">
                  {dateTimeReadable(
                    new Date(lastCurrentYearReceiptStatus?.created_at)
                  )}
                </span>{" "}
                avec{" "}
                <span
                  className="font-semibold"
                  style={{
                    color: lastCurrentYearReceiptStatus.success
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  }}
                >
                  {lastCurrentYearReceiptStatus.success ? "succès" : "erreur"}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Divider />
        <div>
          <LoadingContainer
            loading={loadingTransactions}
            error={loadingTransactionsError}
          >
            <NoElements
              elements={donorTransactions}
              noElementsText="Aucune transactions à afficher"
              onRefresh={fetchDonorTransactions}
            >
              <div className={"flex flex-col flex-1"}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label="Historique de dons" />
                  <Tab label="Liste de reçus" />
                </Tabs>
                {tabValue === 0 ? (
                    <Stack spacing={2}>
                      <div></div>
                      {donorTransactions?.map((transaction) => (
                          <Card key={transaction._id}>
                            <CardContent>
                              <div>
                                <div className="font-semibold">
                                  {dateTimeReadable(transaction.created_at)}
                                </div>
                                <div className="container-main mt-4 [&>div]:w-1/2 space-y-3 [&>div]:flex [&>div]:flex-1 [&>div]:justify-between">
                                  <div>
                                    Campagne <i>{transaction.campaign.name}</i>
                                  </div>
                                  <div className="border-b-[1px] border-b-gray-400">
                                    Don{" "}
                                    <strong>
                                      {numberWithCommas(transaction.amount)} $
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                      ))}
                    </Stack>
                ) : (
                    <></>
                )}
                {tabValue === 1 ? (
                    <div className="flex flex-1 h-screen mt-4">
                      <TableVirtuoso
                          rows={lastReceiptStatus}
                          columns={[
                            { key: "year", label: "Année" },
                            { key: "status", label: "Statut" },
                          ]}
                      />
                    </div>
                ) : (
                    <></>
                )}
              </div>
            </NoElements>
          </LoadingContainer>
        </div>
      </div>
    </LoadingContainer>
  );
};

export default Donor;
